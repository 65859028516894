/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BannerMessage = {
    messageId?: string;
    message: string;
    severity: BannerMessage.severity;
    creatorUserId?: string;
    createdDate?: string;
    updatedDate?: string;
    expirationDate: string;
};

export namespace BannerMessage {

    export enum severity {
        INFO = 'INFO',
        WARNING = 'WARNING',
        ERROR = 'ERROR',
    }


}

