/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * TripDay
 */
export type TripDay = {
    /**
     * Workday Id of the person who took this trip.
     */
    personId: string;
    date: string;
    taxYear?: string;
    tripId?: string;
    organization?: string;
    contractName?: string;
    gaTrackerId?: string;
    individual?: string;
    tripStatus?: TripDay.tripStatus;
    day?: string;
    dayType?: string;
    dayReason?: string;
    countryCode?: string;
    country?: string;
    stateCode?: string;
    state?: string;
    city?: string;
    verified?: boolean;
    cfaId?: string;
    createdDate?: string;
    firstName?: string;
    lastName?: string;
    expiration?: string;
    /**
     * Reason for manually creating a trip. If field does not existit indicates that the trip was system generated.
     */
    creationReason?: string;
};

export namespace TripDay {

    export enum tripStatus {
        OPEN = 'OPEN',
        VERIFIED = 'VERIFIED',
        PROCESSED = 'PROCESSED',
    }


}

