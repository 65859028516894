/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */

import React, {
  forwardRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import scrollIntoView from 'scroll-into-view-if-needed';
import { produce } from 'immer';
import './SearchDropdown.scss';
import { useId } from '@reach/auto-id';
import reactNodeToString from '../internal/utils/reactNodeToString';
import { InputLabel } from '../internal/components/InputLabel';
import { BasePopper } from '../internal/components/BasePopper';
import { HelperText } from '../internal/components/HelperText';
import { clampNumber } from '../internal/utils/clamp';
import { Key, AsyncStatus } from '../internal/enums';
import {
  useCSSPrefix,
  useForwardedRef,
  useInfiniteScroll,
  useSearcher,
  useDescriptiveText,
} from '../internal/hooks';
import { Icon } from '../Icon';
import { LoadingIndicator } from '../LoadingIndicator';
import { FormFieldProps } from '../internal/interfaces';
import {
  announceFirstFocus,
  announceSearchSuccess,
  announceSelect,
} from './ScreenReader';

/**
 * Some functions are available for a consumer to use imperatively, by getting a ref to the SearchDropdown.
 */
export interface SearchDropdownAPI {
  /**
   * Closes the dropdown.
   */
  close: () => void;
  /**
   * Focuses the dropdown.
   */
  focus: () => void;
  /**
   * Options are rendered once, then cached. If you need to force an option to be rerendered,
   * like to hide a search suggestion, you can call this method to invalidate the cache.
   */
  invalidateOptionsCache: () => void;
  /**
   * Opens the dropdown.
   */
  open: () => void;
  /**
   * Opens the dropdown and searches for the query. If your API supports search suggestions,
   * you can call this method when a suggestion is selected.
   */
  openAndSearch: (query: string) => void;
}

/**
 * @typedef {Object} SearchDropdownRenderedOption
 * @property {number} x - The X Coordinate
 * @property {number} y - The Y Coordinate
 */

export interface SearchDropdownRenderedOption {
  /**
   * An identifier for an option, doesn't have to be unique.
   * This is used internally to keep track of which option is selected.
   */
  id: string;
  /**
   * Whether this option is hidden. This can be used to effectively remove items from the list
   * without searching again, which may be useful for search suggestions.
   */
  isHidden?: boolean;
  /**
   * The text value of the option. This is used for screen reader support.
   */
  textValue: string;
  /**
   * The rendered option. Will be cached. For most cases, you can just return a string here.
   */
  rendered: ReactNode;
}

interface SearchDropdownRenderedOptionInternal<TOption>
  extends SearchDropdownRenderedOption {
  option: TOption;
}

// this component does not extend html props, as many html event handlers and aria attributes will not work expectedly
export interface SearchDropdownProps<TOption, TSearchCursor = any>
  extends FormFieldProps {
  /**
   * Defaults to 200, number of milliseconds to wait before calling `onSearch` while the user is typing.
   * Set it to `0` to disable debouncing.
   */
  debounceDelay?: number;
  /**
   * A ref that will be attached to the text input.
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * You can optionally customize what's displayed while loading.
   */
  loader?: ReactNode;
  /**
   * Called when the selected value changes.
   */
  onChange: (newValue: TOption | undefined) => void;
  /**
   * You can optionally handle any search errors, such as to log them to a metrics platform. By default,
   * they're passed to `console.error`.
   */
  onError?: (error: any) => void;
  /**
   * Called when the dropdown needs search results. Will be passed:
   * - `query: string` The text that the user has typed into the search input. Will be blank
   * if the user opens the dropdown before typing anything.
   * - `offset: number`: The index of the next search result to load. This increases from 0 as
   * the user scrolls down.
   * - `cursor: TSearchCursor` Optional search session data returned from the previous call to onSearch,
   * this is useful for search backends that use tokens for pagination instead of limit/offset.
   * Will be reset to `undefined` if the user changes their query.
   *
   * Expected to return:
   * - `canLoadMore: boolean` Whether there are more results to load. Used for infinite scroll.
   * - `results: TOption[]` Search results from your API.
   * - `cursor?: TSearchCursor` A search cursor that will be passed to the next call to onSearch.
   */
  onSearch: (
    /**
     * The text that the user has typed into the search input. This will be blank if the user opens
     * the dropdown before typing anything.
     */
    query: string,
    /**
     * The index of the next search result to load. This increases from 0 as the user scrolls down.
     */
    offset: number,
    /**
     * Optional search session data returned from the previous call to onSearch,
     * this is useful for search backends that use tokens for pagination instead of limit/offset.
     * Will be reset to `undefined` if the user changes their query.
     */
    cursor?: TSearchCursor
  ) => Promise<{
    /**
     * Whether there are more results to load. Used for infinite scroll.
     */
    canLoadMore: boolean;
    /**
     * Search results from your API.
     */
    results: TOption[];
    /**
     * You can optionally return session data that will be passed through when calling onSearch again.
     * This is useful for search backends that use tokens for pagination instead of limit/offset.
     */
    cursor?: TSearchCursor;
  }>;
  /**
   * The content displayed when an API call errors.
   */
  renderError?: (error: any) => ReactNode;
  /**
   * The content displayed when a search yields no results.
   */
  renderNoResults?: (query: string) => ReactNode;
  /**
   * Will be passed an option to render, expected to return the following:
   * - `id: string` An identifier for an option, doesn't have to be unique. This is used internally to
   * keep track of which option is selected.
   * - `isHidden?: boolean` Whether this option is hidden. This can be used to hide items from the list
   * without searching again, which can be useful for search suggestions.
   * - `textValue: string` The text value of the option. This is used for screen reader support.
   * - `rendered: ReactNode` The rendered option. Will be cached. For most cases, you can just return
   * a string here.
   */
  renderOption: (option: TOption) => {
    /**
     * An identifier for an option, doesn't have to be unique.
     * This is used internally to keep track of which option is selected.
     */
    id: string;
    /**
     * Whether this option is hidden. This can be used to effectively remove items from the list
     * without searching again, which may be useful for search suggestions.
     */
    isHidden?: boolean;
    /**
     * The text value of the option. This is needed for screen reader support.
     */
    textValue: string;
    /**
     * The rendered option. Will be cached. For most cases, you can just return a string here.
     */
    rendered: ReactNode;
  };
  /**
   * Shows a dividing line between options. This should only be turned on for options that need
   * visual separation, like for a list of users with a name and an email for each user.
   */
  showOptionDivider?: boolean;
  /**
   * The selected search result.
   */
  value: TOption | null | undefined;
}

interface State {
  inputValue: string;
  isFocused?: boolean;
  /**
   * Keeps track of whether our last navigation was from the keyboard. If it was,
   * we apply slightly different styling to the navigated item.
   */
  isKeyboardNav?: boolean;
  isOpen: boolean;
  navigationIndex: number;
}

/**
 * We're keeping the input focused at all times for accessibility and so keyboard nav works all the time.
 * Here are our desired behaviors:
 * Nothing selected:
 *  - If placeholder: show placeholder
 *  - User clicks into input: hide placeholder, show input
 *
 * Option is Selected:
 *  - show option
 *  - if focused: show option, show cursor slightly to left of it? Maybe fade option opacity
 *  - if user starts typing: hide option, input fills space
 */
enum InputMode {
  Empty = 'Empty',
  Placeholder = 'Placeholder',
  Value = 'Value',
  InputAndValue = 'InputAndValue',
  Input = 'Input',
}

/**
 * Allows the user to search an API and select a result in a dropdown.
 */
export const SearchDropdown = forwardRef(function SearchDropdown<
  TOption,
  TSearchCursor = any
>(
  {
    className,
    debounceDelay = 200,
    disabled,
    errorText,
    successText,
    fullWidth,
    helperText,
    hideRequiredStyle,
    id: propsId,
    inputRef: propsInputRef,
    label,
    loader,
    margin = false,
    onChange,
    onError,
    onSearch,
    placeholder,
    readOnly = false,
    renderError,
    renderNoResults,
    renderOption,
    required,
    showOptionDivider,
    compact = false,
    style,
    value,
    ...otherProps
  }: SearchDropdownProps<TOption, TSearchCursor>,
  imperativeRef: React.Ref<SearchDropdownAPI> | undefined
) {
  const [
    { inputValue, isFocused, isKeyboardNav, isOpen, navigationIndex },
    setState,
  ] = useState<State>({
    inputValue: '',
    isOpen: false,
    navigationIndex: -1,
  });

  const [cssPrefix] = useCSSPrefix();
  const id = useId(propsId);
  const labelId = `search-dropdown-label-${id}`;
  const listboxId = `search-dropdown-listbox-${id}`;
  const closedValueHtmlId = `search-dropdown-closed-value-${id}`;
  const placeholderHtmlId = `search-dropdown-placeholder-${id}`;
  const popperReferenceEl = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const { status } = useDescriptiveText(errorText, successText, helperText);

  /*
    We're accessing searcher properties directly instead of destructuring them, so that we avoid stale state.
    I think this approach is less likely to result in bugs. The alternative is to use a bunch of sequential
    effects, which could be tricky to get right.
  */
  const {
    searcher,
    results,
    tryLoadMore,
    reset: searcherReset,
    search,
    searchDebounced,
  } = useSearcher<TOption, TSearchCursor>(onSearch, onError, { debounceDelay });

  // We allow consumers to manually invalidate our cache. The use case is for search suggestions,
  // where a suggestion might have an "X" button that allows the user to remove it. In that case,
  // the consumer could do an API call to remove the suggestion, change their `renderOption`
  // callback to return `isHidden: true`, then invalide the cache.
  const [optionsCacheInvalidator, setOptionsCacheInvalidator] = useState(0);
  const invalidateOptionsCache = useCallback(() => {
    setOptionsCacheInvalidator((count) => {
      return count + 1;
    });
  }, []);

  // Cache rendered options. We're still rerendering them all on loadMore, we could be more efficient
  // by caching the previously rendered items, but then we have to keep track of invalidating the cache.
  // This should be good enough for now.
  const renderedOptions = useMemo(() => {
    return (
      results
        .map((result) => {
          const rendered = renderOption(result);
          return {
            ...rendered,
            // Add the original result in the returned object, we'll need this when selecting.
            option: result,
          };
        })
        // Remove hidden options immediately
        .filter((renderedOption) => !renderedOption.isHidden)
    );
  }, [results, optionsCacheInvalidator]);

  const renderedSelectedOption = useMemo(() => {
    return value && renderOption(value);
  }, [value, optionsCacheInvalidator]);

  const { scrollerRef } = useInfiniteScroll(renderedOptions, tryLoadMore);

  // To scroll to an option for keyboard nav, we need a ref for every result.
  const optionRefs: React.RefObject<HTMLLIElement>[] = useMemo(() => {
    return renderedOptions.map(() => React.createRef<HTMLLIElement>());
  }, [renderedOptions]);

  // We need html ID's for each option for screen reader support.
  const optionHtmlIds = useMemo(() => {
    return renderedOptions.map(
      (_, index) => `search-dropdown-option-${id}-${index}`
    );
  }, [renderedOptions]);

  const navigationOptionHtmlId = optionHtmlIds[navigationIndex];

  // The label can be any element, but we need a string for screenreaders.
  const labelStringValue = useMemo(() => {
    return reactNodeToString(label);
  }, [label]);

  // Announce when we first load search results. This is unnecessary when loading more
  // because the total results count is announced for each item when doing keyboard navigation.
  const lastSearcherStatus = useRef(searcher.status);
  useEffect(() => {
    if (
      isOpen &&
      isFocused &&
      lastSearcherStatus.current === AsyncStatus.Loading &&
      searcher.status === AsyncStatus.Success
    ) {
      // We're using "renderedOptions" instead of "results" because we want to take into account
      // result hiding.
      announceSearchSuccess(renderedOptions.length);
    }

    lastSearcherStatus.current = searcher.status;
    // We're deliberately only running this effect on searcher status changes. If we're not open
    // or focused at the time, we'll skip the announcement.
  }, [searcher.status]);

  // We need to manually announce the current value when we get focused, because we support
  // html rendering for the value instead of just text.
  const lastFocused = useRef(isFocused);
  useEffect(() => {
    if (!lastFocused.current && isFocused) {
      announceFirstFocus(
        labelStringValue,
        renderedSelectedOption?.textValue || '',
        readOnly
      );
    }

    lastFocused.current = isFocused;
  }, [isFocused]);

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const close = useCallback(
    function close() {
      // Allow closing even if disabled or readOnly so we don't get stuck open.
      if (!isOpen) return;

      setState(
        produce((draft) => {
          draft.isOpen = false;
        })
      );
    },
    [isOpen]
  );

  const open = useCallback(
    function open() {
      if (disabled || readOnly || isOpen) return;

      // Search if we haven't yet.
      if (
        searcher.status === AsyncStatus.Initial ||
        searcher.status === AsyncStatus.Error
      ) {
        search(inputValue);
      }

      setState(
        produce((draft) => {
          draft.isOpen = true;
        })
      );
    },
    [disabled, readOnly, isOpen, inputValue]
  );

  const openAndSearch = useCallback(
    function openAndSearch(query: string) {
      if (disabled || readOnly) return;

      search(query);
      setState(
        produce((draft) => {
          draft.inputValue = query;
          draft.isOpen = true;
        })
      );
      focusInput();
    },
    [disabled, readOnly]
  );

  // Close if we get disabled while open
  useEffect(() => {
    if (isOpen && (disabled || readOnly)) {
      close();
    }
  }, [isOpen, disabled, readOnly]);

  /*
    We're exposing some functionality so that consumers can do things like set the input text,
    which is necessary to support "search suggestions" functionality. I think this imperative approach
    is simpler than letting consumers directly change our input text. If we were to go that route, we would
    need effects to listen for changes to things like isOpen and inputText, so that we could open or search.
    However, that gets tricky because we do something slightly different in each case.
  */
  useImperativeHandle(
    imperativeRef,
    () => ({
      close,
      focus: focusInput,
      invalidateOptionsCache,
      open,
      openAndSearch,
    }),
    [close, focusInput, invalidateOptionsCache, open, openAndSearch]
  );

  function select(
    renderedOption: SearchDropdownRenderedOptionInternal<TOption>
  ) {
    if (disabled || readOnly) return;

    if (inputValue) {
      searcherReset();
    }

    // Close and clear
    setState(
      produce((draft) => {
        draft.isOpen = false;
        draft.navigationIndex = -1;
        draft.inputValue = '';
      })
    );

    announceSelect(renderedOption.textValue);
    onChange(renderedOption.option);
  }

  // Moves the navigated option index by `moveDiff`. This is used by keyboard navigation
  // for both up and down.
  function navigateAndScroll(moveDiff: number) {
    if (disabled || readOnly || !isOpen) return;

    if (renderedOptions.length === 0) {
      setState(
        produce((draft) => {
          draft.navigationIndex = -1;
        })
      );
      return;
    }

    // Default to just selecting the first option. If we already have an option selected,
    // we'll adjust the index.
    let newIndex = 0;

    if (navigationIndex !== -1) {
      // Move the index, then clamp to the array bounds
      newIndex = clampNumber(
        navigationIndex + moveDiff,
        0,
        renderedOptions.length - 1
      );
    }

    navigate(newIndex, true);

    // Scroll to the selected item. We're using a helper lib to only scroll if needed.
    let resultRef: React.RefObject<HTMLLIElement | HTMLDivElement> =
      optionRefs[newIndex];
    // If the user has pressed Down on the last loaded item, it feels nice to scroll to the loader
    // instead of sitting on the last item.
    if (moveDiff > 0 && newIndex === navigationIndex && loaderRef.current) {
      resultRef = loaderRef;
    }

    if (resultRef && resultRef.current) {
      scrollIntoView(resultRef.current, {
        scrollMode: 'if-needed',
        // Align to "end" if we're scrolling down, align to "start" for scrolling up.
        block: moveDiff > 0 ? 'end' : 'start',
        inline: 'start',
      });
    }
  }

  function navigate(newNavigationIndex: number, isKeyboard: boolean) {
    if (disabled || readOnly || !isOpen) return;

    setState(
      produce((draft) => {
        draft.isKeyboardNav = isKeyboard;
        draft.navigationIndex = newNavigationIndex;
      })
    );
  }

  function onFocusInput() {
    if (!isFocused) {
      setState(
        produce((draft) => {
          draft.isFocused = true;
        })
      );
    }
  }

  function onBlurInput() {
    // If we have input text, clear it. This means we should also reset the searcher because
    // our search results should correspond to the input text.
    const shouldReset = !!inputValue;
    if (shouldReset) {
      searcherReset();
    }

    if (isFocused) {
      setState(
        produce((draft) => {
          draft.isFocused = false;

          if (shouldReset) {
            draft.inputValue = '';
            draft.navigationIndex = -1;
          }
        })
      );
    }

    close();
  }

  function onButtonClick() {
    if (disabled || readOnly) return;

    isOpen ? close() : open();
    focusInput();
  }

  function onClear() {
    if (disabled || readOnly) return;

    setState(
      produce((draft) => {
        draft.inputValue = '';
        draft.navigationIndex = -1;
      })
    );

    searcherReset();
    search('');
    focusInput();
    open();
    onChange(undefined);
  }

  function onKeyDownInput(e: React.KeyboardEvent) {
    if (disabled || readOnly) return;

    switch (e.key) {
      // Move selection
      case Key.ArrowDown:
      case Key.ArrowUp:
        e.preventDefault();
        if (!isOpen) {
          open();
        }

        navigateAndScroll(e.key === Key.ArrowDown ? 1 : -1);
        break;

      // Select item
      case Key.Enter:
        if (!isOpen) {
          open();
        } else if (navigationIndex !== -1) {
          const opt = renderedOptions[navigationIndex];
          if (opt) {
            select(opt);
          }
        }
        break;

      // Close
      case Key.Escape:
        if (isOpen || inputValue) {
          e.preventDefault();
          setState(
            produce((draft) => {
              draft.inputValue = '';
              draft.navigationIndex = -1;
            })
          );
          // We only need to reset if the user had typed something.
          if (inputValue) searcherReset();
          close();
        }
        break;

      // Close, don't prevent default so that we don't stop tab navigation.
      case Key.Tab:
        if (isOpen) {
          close();
        }
        break;
    }
  }

  function onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
    if (disabled || readOnly) return;

    const val = e.target.value;

    setState(
      produce((draft) => {
        draft.inputValue = val;
        draft.navigationIndex = -1;
      })
    );

    if (debounceDelay === 0) {
      search(val);
    } else {
      searchDebounced(val);
    }

    open();
  }

  function onMouseDown(e: MouseEvent<HTMLDivElement>) {
    if (disabled || readOnly) return;

    // Prevent default to stop focus from leaving the input.
    if (isFocused && (e.target as Element).tagName !== 'INPUT') {
      e.preventDefault();
    }
  }

  function onClickContent() {
    open();
    focusInput();
  }

  // We're managing hover state via javascript so that it works for keyboard nav.
  function onEnterItem(index: number) {
    if (disabled || readOnly) return;

    navigate(index, false);
  }

  // This gets called when BasePopper tells us to close
  function onDropdownSetShow(
    newShow: boolean | ((newShow: boolean) => boolean)
  ) {
    // The BasePopper requires us to implement the setState function callback style.
    const actualNewShow =
      typeof newShow === 'function' ? newShow(isOpen) : newShow;

    actualNewShow ? open() : close();
  }

  function onClickOption(
    e: MouseEvent<HTMLElement>,
    renderedOption: SearchDropdownRenderedOptionInternal<TOption>
  ) {
    if (e.isDefaultPrevented()) return;

    e.preventDefault();

    focusInput();
    select(renderedOption);
  }

  let inputMode = InputMode.Empty;

  if (isOpen) {
    // Always show the input when open, show the value if we have one.
    if (inputValue || !value) {
      inputMode = InputMode.Input;
    } else if (value) {
      inputMode = InputMode.InputAndValue;
    }
  } else {
    if (value) {
      inputMode = InputMode.Value;
    } else if (placeholder) {
      inputMode = InputMode.Placeholder;
    }
  }

  const hideInput =
    inputMode !== InputMode.Input && inputMode !== InputMode.InputAndValue;

  const showCursorOnly = inputMode === InputMode.InputAndValue;

  return (
    <div
      {...otherProps}
      className={clsx(
        className,
        `${cssPrefix}-search-dropdown-wrap`,
        `${cssPrefix}-margin-${margin}`,
        fullWidth && `${cssPrefix}-full-width`
      )}
    >
      <InputLabel
        className={`${cssPrefix}-search-dropdown-label`}
        disabled={disabled}
        htmlFor={id}
        hideRequiredStyle={hideRequiredStyle}
        id={labelId}
        required={required}
      >
        {label}
      </InputLabel>
      <div
        className={clsx(
          `${cssPrefix}-search-dropdown`,
          compact && `${cssPrefix}-compact`,
          isFocused && `${cssPrefix}-focused`,
          disabled && `${cssPrefix}-disabled`,
          readOnly && `${cssPrefix}-read-only`,
          status && `${cssPrefix}-${status}`
        )}
        onMouseDown={onMouseDown}
        ref={popperReferenceEl}
      >
        <div
          className={`${cssPrefix}-search-dropdown-content`}
          onClick={onClickContent}
        >
          <input
            aria-activedescendant={isOpen ? navigationOptionHtmlId : ''}
            aria-autocomplete="list"
            aria-controls={listboxId}
            // We have to set this attribute even when false
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-haspopup="listbox"
            aria-labelledby={labelId}
            aria-required={required}
            aria-describedby={`${id}-helper-text`}
            autoCapitalize="none"
            autoComplete="off"
            className={clsx(
              `${cssPrefix}-search-dropdown-input`,
              hideInput && `${cssPrefix}-search-dropdown-input-hidden`,
              showCursorOnly && `${cssPrefix}-search-dropdown-input-cursor-only`
            )}
            disabled={disabled}
            onBlur={onBlurInput}
            onChange={onChangeInput}
            onFocus={onFocusInput}
            onKeyDown={onKeyDownInput}
            readOnly={readOnly}
            ref={useForwardedRef(propsInputRef, inputRef)}
            id={id}
            role="combobox"
            spellCheck="false"
            type="text"
            value={inputValue}
            aria-disabled={hideInput}
          />
          {(inputMode === InputMode.Value ||
            inputMode === InputMode.InputAndValue) && (
            <ul
              className={clsx(
                `${cssPrefix}-search-dropdown-value`,
                inputMode === InputMode.InputAndValue &&
                  `${cssPrefix}-with-input`
              )}
              id={closedValueHtmlId}
            >
              {renderedSelectedOption?.rendered}
            </ul>
          )}
          {inputMode === InputMode.Placeholder && !disabled && !readOnly && (
            <div
              className={`${cssPrefix}-search-dropdown-placeholder`}
              id={placeholderHtmlId}
              aria-disabled
            >
              {placeholder}
            </div>
          )}
        </div>
        {value && !disabled && !readOnly && (
          <button
            aria-label="Clear selection"
            className={`${cssPrefix}-search-dropdown-clear-button`}
            disabled={disabled || readOnly}
            onClick={onClear}
            title="Clear"
            type="button"
          >
            <Icon icon="close" aria-hidden />
          </button>
        )}
        <button
          // eslint-disable-next-line react/no-unknown-property
          aria-hidden
          className={`${cssPrefix}-search-dropdown-open-button`}
          disabled={disabled || readOnly}
          onClick={onButtonClick}
          // Removing from tabs because keyboard users will open the dropdown via keyboard nav.
          tabIndex={-1}
          type="button"
        >
          <Icon icon="search" aria-hidden />
        </button>
      </div>
      <HelperText
        id={`${id}-helper-text`}
        errorText={errorText}
        successText={successText}
        helperText={helperText}
      />
      <BasePopper
        sameWidthAsReferenceElement
        show={isOpen}
        setShow={onDropdownSetShow}
        placement="bottom-start"
        referenceElement={popperReferenceEl.current}
        className={`${cssPrefix}-search-dropdown-popup`}
      >
        <div
          className={`${cssPrefix}-search-dropdown-popup-inner`}
          onMouseDown={onMouseDown}
          ref={scrollerRef}
        >
          {renderedOptions.length === 0 &&
            searcher.status === AsyncStatus.Success && (
              <div className={`${cssPrefix}-search-dropdown-no-results`}>
                {renderNoResults && renderNoResults(inputValue)}
                {!renderNoResults && 'No results found.'}
              </div>
            )}
          {renderedOptions.length > 0 && (
            <ul
              className={clsx(
                `${cssPrefix}-search-dropdown-list`,
                `${cssPrefix}-search-dropdown-show-divider`
              )}
              id={listboxId}
              role="listbox"
            >
              {renderedOptions.map((renderedOption, index) => {
                const isSelected =
                  renderedSelectedOption?.id &&
                  renderedSelectedOption?.id === renderedOption.id;
                const isNavigated = index === navigationIndex;
                const isLastItem =
                  searcher.status === AsyncStatus.Success &&
                  !searcher.canLoadMore &&
                  index === renderedOptions.length - 1;

                return (
                  <li
                    aria-selected={isSelected ? 'true' : 'false'}
                    className={clsx([
                      `${cssPrefix}-search-dropdown-item`,
                      isNavigated && `${cssPrefix}-focused`,
                      isKeyboardNav &&
                        isNavigated &&
                        `${cssPrefix}-focused-keyboard`,
                      isSelected && `${cssPrefix}-selected`,
                      showOptionDivider &&
                        !isLastItem &&
                        `${cssPrefix}-search-dropdown-item-divider`,
                    ])}
                    id={optionHtmlIds[index]}
                    // NOTE: It's safer to assume that the id returned by consumers may not be unique.
                    // Items can't be removed or reordered, so index is fine.
                    key={index}
                    onClick={(e) => onClickOption(e, renderedOption)}
                    onMouseEnter={() => onEnterItem(index)}
                    ref={optionRefs[index]}
                    role="option"
                  >
                    <div className={`${cssPrefix}-search-dropdown-item-inner`}>
                      {renderedOption.rendered}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {searcher.status === AsyncStatus.Error && (
            <>
              {renderError && renderError(searcher.error)}
              {!renderError && (
                <div className={`${cssPrefix}-search-dropdown-error`}>
                  <Icon
                    icon="error-filled"
                    className={`${cssPrefix}-search-dropdown-error-icon`}
                    aria-label={`Error: ${searcher.error}`}
                  />
                  {`${searcher.error}`}
                </div>
              )}
            </>
          )}
          {/* Show the loader if we can load more. Otherwise, there's a scrollbar jump
        when the loader pops in when you scroll down, feels kind of awkward. */}
          {(searcher.canLoadMore ||
            searcher.status === AsyncStatus.Loading) && (
            <div
              className={`${cssPrefix}-search-dropdown-loading`}
              ref={loaderRef}
            >
              {loader || (
                <LoadingIndicator
                  size="sm"
                  variant="inline"
                  style={{ marginRight: '4px' }}
                />
              )}
            </div>
          )}
        </div>
      </BasePopper>
    </div>
  );
  // "forwardRef" doesn't support generics, so we have to explicitly declare our type.
}) as <TOption, TSearchCursor = any>(
  props: SearchDropdownProps<TOption, TSearchCursor> &
    React.RefAttributes<SearchDropdownAPI>
) => ReactElement | null;
