import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  HTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useId } from '@reach/auto-id';
import clsx from 'clsx';
import { findLast, toArray } from 'lodash';
import { Key } from '../internal/enums';
import { CommonProps } from '../internal/interfaces';
import { SelectedSlider } from './SelectedSlider';
import { Icon } from '../Icon';
import { useCSSPrefix, useIsOverflow } from '../internal/hooks';
import { TabsContext } from './TabsContext';
import './Tabs.scss';

export type TabsColor = 'primary' | 'secondary';

export interface TabsProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'>,
    CommonProps {
  /**
   * The item key that corresponds to the currently selected Tab Item.
   */
  activeItemKey: string;
  /**
   * Callback fired when a Tab Item is clicked
   */
  onChange: (itemKey: string) => void;
  /**
   * Optionally enable the Tab Items to span the full width of it's Tabs container
   */
  fullWidth?: boolean;
  /**
   * Tab Items are left aligned by default, but can optionally be centered.
   */
  centered?: boolean;
  /**
   * Specify the color Tabs from the following list of options:
   */
  color?: TabsColor;
}

export const Tabs = ({
  id: idProp,
  children,
  activeItemKey: controlledState,
  fullWidth = false,
  centered = false,
  color = 'primary',
  onChange,
  className,
  ...props
}: TabsProps) => {
  const [keys, setKeys] = useState<HTMLButtonElement[]>([]);

  const [navigationValue, setNavigationValue] = useState<string>('');
  const [activeRef, setActiveRef] = useState<HTMLElement | null>(null);

  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);

  const listRef = useRef<HTMLDivElement | null>(null);

  const isOverflow = useIsOverflow(listRef);
  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);

  // Scrolls when the chevron is clicked
  const scroll = (shift?: number) => {
    if (!listRef.current || !shift) return;
    listRef.current.scrollLeft += shift;
    setScrollX(scrollX + shift);

    if (
      Math.floor(listRef.current.scrollWidth - listRef.current.scrollLeft) <=
      listRef.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  // Checks whether to render the left/right chevrons
  const scrollCheck = () => {
    !!listRef.current && setScrollX(listRef.current.scrollLeft);
    if (
      !!listRef.current &&
      Math.floor(listRef.current.scrollWidth - listRef.current.scrollLeft) <=
        listRef.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  useEffect(() => {
    const element = keys.find(
      (key) => key.id === `item-${controlledState}`
    ) as HTMLButtonElement;
    setActiveRef(element);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [controlledState]);

  useEffect(() => {
    if (listRef.current) {
      const tabItems = toArray(
        listRef.current.children[1].children[0].children
      ).map((child) => child.children[0]) as HTMLButtonElement[];

      const initIndex = tabItems.indexOf(
        tabItems.find(
          (c) => c.id === `item-${controlledState}`
        ) as HTMLButtonElement
      );

      setActiveRef?.(tabItems[initIndex]);
      setKeys?.(tabItems);
    }
  }, [fullWidth, centered]);

  useEffect(() => {
    // Do the required references exist? Essentially, has the component completed its first render?
    if (keys?.length === 0 || !listRef.current) return;

    const navigatedElement = keys?.find((opt) => opt.id === navigationValue);

    // Is one of the tab items in focus?
    if (!navigatedElement) return;

    const scrollDistance = listRef.current.getBoundingClientRect().width / 2;
    const { left: itemLeftEdge, right: itemRightEdge } =
      navigatedElement.getBoundingClientRect();

    if (itemRightEdge > listRef.current.clientWidth) {
      return scroll(scrollDistance);
    }

    if (itemLeftEdge < 0) {
      return scroll(-scrollDistance);
    }
  }, [navigationValue]);

  useEffect(() => {
    if (activeRef && navigationValue !== '') {
      activeRef.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [activeRef]);

  const value = React.useMemo(
    () => ({
      keys,
      onChange,
      activeRef,
      setActiveRef,
      navigationValue,
      setNavigationValue,
      fullWidth,
      color,
    }),
    [
      keys,
      onChange,
      activeRef,
      setActiveRef,
      navigationValue,
      setNavigationValue,
      fullWidth,
      color,
    ]
  );

  return (
    <TabsContext.Provider value={value}>
      <div
        {...props}
        id={id}
        className={clsx([`${cssPrefix}-tabs`, className])}
      >
        <div
          ref={listRef}
          className={clsx([
            `${cssPrefix}-tabs-list-wrapper`,
            isOverflow &&
              `${cssPrefix}-left-overlay ${cssPrefix}-right-overlay`,
            scrollX === 0
              ? `${cssPrefix}-fadeout-left`
              : `${cssPrefix}-fadein-left`,
            scrollEnd
              ? `${cssPrefix}-fadeout-right`
              : `${cssPrefix}-fadein-right`,
          ])}
          onScroll={scrollCheck}
        >
          <div className={`${cssPrefix}-tabs-list-nav`}>
            {isOverflow && scrollX !== 0 && (
              <button
                aria-label="previous"
                type="button"
                tabIndex={-1}
                className={`${cssPrefix}-chev-left`}
                onClick={() => {
                  scroll(
                    (listRef.current?.getBoundingClientRect().width ?? 0) * -1
                  );
                }}
              >
                <Icon size="xs" icon="chevron-left" />
              </button>
            )}

            {isOverflow && !scrollEnd && (
              <button
                aria-label="next"
                type="button"
                tabIndex={-1}
                className={`${cssPrefix}-chev-right`}
                onClick={() =>
                  scroll(listRef.current?.getBoundingClientRect().width)
                }
              >
                <Icon size="xs" icon="chevron-right" />
              </button>
            )}
          </div>
          <div
            className={clsx([
              `${cssPrefix}-tabs-list-inner`,
              `${cssPrefix}-tab-item-color-${color}`,
            ])}
          >
            <div
              className={clsx([
                `${cssPrefix}-tabs-list`,
                centered && !isOverflow && `${cssPrefix}-tabs-centered`,
              ])}
              role="tablist"
            >
              {children}
            </div>
            {controlledState !== undefined && !!onChange && (
              <SelectedSlider sliderRootEl={listRef} />
            )}
          </div>
        </div>
      </div>
    </TabsContext.Provider>
  );
};

export interface TabItemProps
  extends HTMLAttributes<HTMLDivElement>,
    CommonProps {
  /**
   * A unique key to associate a Tab Item with a Tab Panel
   */
  itemKey: string;
  /**
   * Specify whether the Tab Item should be disabled, or not
   *
   * @default false
   */
  disabled?: boolean;
}

export const TabItem = ({
  itemKey,
  children,
  className,
  disabled = false,
  ...rest
}: TabItemProps) => {
  const [cssPrefix] = useCSSPrefix();
  const {
    onChange,
    activeRef,
    setNavigationValue,
    navigationValue,
    keys,
    fullWidth,
    color,
  } = useContext(TabsContext) || {};

  const isSelected = (tabId: string) => activeRef?.id === tabId;

  const tabItemRef = useRef<HTMLButtonElement | null>(null);

  const navigate = (option?: string) => {
    if (!option) return;
    setNavigationValue?.(option);
    requestAnimationFrame(() => {
      document.getElementById(option)?.focus();
    });
  };

  const getOption = ({
    isNext = false,
    loop = false,
  }: { isNext?: boolean; loop?: boolean } = {}) => {
    if (keys?.length === 0) return undefined;

    let activeItemKey = keys?.findIndex((opt) => opt.id === navigationValue);

    // select first tab if no tab selected
    if (activeItemKey === -1) activeItemKey = 0; // first option is disabled, can't select immediately select it, so we'll need to do the loop.

    // moves the index by 1, either forward or backwards. loops around if we go out of bounds.
    const moveIndex = (curIndex: number): number => {
      let newIndex = curIndex + (isNext ? 1 : -1);

      if (newIndex >= keys!.length) {
        if (loop) {
          newIndex = 0;
        } else {
          // prevents infinite loop if last element is disabled and next element is navigated to
          if (keys![newIndex - 1].disabled) {
            newIndex = keys?.indexOf(
              findLast(keys, (key) => !key.disabled) as HTMLButtonElement
            ) as number;
          } else {
            newIndex -= 1;
          }
        }
      } else if (newIndex < 0) {
        if (loop) {
          newIndex = keys!.length - 1;
        } else {
          // prevents infinite loop if first element is disabled and prev element is navigated to
          newIndex = keys?.indexOf(
            keys.find((key) => !key.disabled) as HTMLButtonElement
          ) as number;
        }
      }
      return newIndex;
    };

    // loop until we find a non-disabled item, or we loop around to our starting point
    for (
      let i = moveIndex(activeItemKey!);
      i !== activeItemKey;
      i = moveIndex(i)
    ) {
      if (!keys![i].disabled) {
        return keys![i].id;
      }
    }

    // couldn't find a non-disabled option
    return undefined;
  };

  const handleButtonKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === Key.ArrowLeft) {
      e.preventDefault();
      return navigate(getOption({ isNext: false, loop: true }));
    }

    if (e.key === Key.ArrowRight) {
      e.preventDefault();
      return navigate(getOption({ isNext: true, loop: true }));
    }

    if (e.key === Key.Tab && e.shiftKey) {
      const updatedNavigationValue = getOption({ isNext: false });
      if (!updatedNavigationValue) return;
      return setNavigationValue?.(updatedNavigationValue);
    }

    if (e.key === Key.Tab && !e.shiftKey) {
      const updatedNavigationValue = getOption({ isNext: true });
      if (!updatedNavigationValue) return;
      return setNavigationValue?.(updatedNavigationValue);
    }
  };

  const handleFocus = ({
    currentTarget: { id },
  }: React.FocusEvent<HTMLButtonElement>) => setNavigationValue?.(id);

  const tabId = `item-${itemKey}`;

  return (
    <div
      {...rest}
      className={clsx([
        `${cssPrefix}-tab-item-wrapper`,
        isSelected?.(tabId) && `${cssPrefix}-tab-item-selected`,
        disabled && `${cssPrefix}-tab-item-disabled`,
        `${cssPrefix}-tab-item-color-${color}`,
        fullWidth && `${cssPrefix}-tab-item-full-width`,
        className,
      ])}
    >
      <button
        type="button"
        role="tab"
        aria-selected={isSelected?.(tabId)}
        id={tabId}
        aria-controls={`panel-${itemKey}`}
        ref={tabItemRef}
        onClick={() => onChange?.(itemKey)}
        onKeyDown={handleButtonKeyDown}
        onFocus={handleFocus} // needed to reset navigated value when tabbed into
        disabled={disabled}
        className={clsx([
          `${cssPrefix}-tab-item`,
          `${cssPrefix}-tab-item-color-${color}`,
          fullWidth && `${cssPrefix}-tab-item-full-width`,
        ])}
      >
        {children}
      </button>
    </div>
  );
};

export interface TabPanelProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<TabItemProps, 'itemKey'>,
    Pick<TabsProps, 'activeItemKey'>,
    CommonProps {}

export const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>(
  ({ itemKey, children, activeItemKey, ...rest }: TabPanelProps, ref) => {
    const [cssPrefix] = useCSSPrefix();

    return (
      <div
        {...rest}
        id={`panel-${itemKey}`}
        role="tabpanel"
        aria-labelledby={`item-${itemKey}`}
        aria-hidden={activeItemKey !== itemKey}
        ref={ref}
        className={clsx([
          `${cssPrefix}-tab-panel`,
          activeItemKey === itemKey && `${cssPrefix}-show-tab-panel`,
        ])}
      >
        {children}
      </div>
    );
  }
);
