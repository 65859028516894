/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BannerMessage } from '../models/BannerMessage';
import type { EmailNotification } from '../models/EmailNotification';
import type { LocationDays } from '../models/LocationDays';
import type { Person } from '../models/Person';
import type { TravelValidationConf } from '../models/TravelValidationConf';
import type { TripDays } from '../models/TripDays';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BusinessTravelService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get list of TripDays of requesting user. Optional parameter to select by TripStatus
     * @returns TripDays OK
     * @throws ApiError
     */
    public getTripDays({
        status,
    }: {
        status?: string,
    }): CancelablePromise<TripDays> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/trips',
            query: {
                'status': status,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add, update, remove, or create trip days. To add a trip day is to update an existing trip day entry block that already exists in the System. This is the same case for updating. However, to create a trip day(s) is to manually create a trip day that does not exist within the Deloitte system. This is known as a manual trip day entry. If the date ranges are minimized for a trip then the trip days that fall out of the new date range will be hard deleted or soft deleted, depending on the origin of the trip creation, from the Travel Validation system.
     * @returns string Success
     * @throws ApiError
     */
    public updateTripDays({
        requestBody,
        createManualTrip,
    }: {
        requestBody: TripDays,
        /**
         *  Boolean that indicates provided tripDays are to be created. Otherwise, update or add to an existing trip day block
         */
        createManualTrip?: boolean,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/trips',
            query: {
                'create-manual-trip': createManualTrip,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add newly created trip days from sftp server. Only allowed by system users.
     * @returns string Success
     * @throws ApiError
     */
    public saveTripDays({
        requestBody,
    }: {
        requestBody: TripDays,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/trips/sftp-load',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Returns whether or not a user is currently masquerading
     * @returns boolean OK; user is either currently masquerading or is not
     * @throws ApiError
     */
    public isMasqueraded({
        cfaId,
    }: {
        cfaId: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/masquerade/session',
            query: {
                'cfa-id': cfaId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden; user is not allowed to masquerade`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Starts a masquerading session for the target person
     * @returns string Accepted; user is now masquerading as the target person
     * @throws ApiError
     */
    public masquerade({
        masqueradeCfaId,
    }: {
        masqueradeCfaId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/masquerade/session',
            query: {
                'masquerade-cfa-id': masqueradeCfaId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden; user is not allowed to masquerade`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Terminates a masquerading session for your user
     * @returns string Accepted; user's masquerade session should now be terminated
     * @throws ApiError
     */
    public clearMasquerade({
        cfaId,
    }: {
        cfaId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/masquerade/session',
            query: {
                'cfa-id': cfaId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden; user is not allowed masquerade`,
            },
        });
    }

    /**
     * Get configuration by ID
     * @returns TravelValidationConf OK
     * @throws ApiError
     */
    public getConfigurationForContextUser(): CancelablePromise<TravelValidationConf> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/configurations',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add or update configurations for a user. This method is used also for adding or removing a delegate to a specific user
     * @returns void
     * @throws ApiError
     */
    public saveUserConfiguration({
        requestBody,
    }: {
        requestBody: TravelValidationConf,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/configurations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * If ack is true, updates user configuration to acknowledge disclaimer
     * @returns any Successfully Upserted
     * @throws ApiError
     */
    public acknowledgeConfiguration({
        ack,
    }: {
        ack: boolean,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/configurations/ack',
            query: {
                'ack': ack,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * endpoint for btvt admins to manually initiate a sftp ingest from the sftp adapter
     * @returns void
     * @throws ApiError
     */
    public initiateSftpIngest(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/ingest',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * endpoint for btvt admins to manually initiate a sftp export from the sftp adapter
     * @returns void
     * @throws ApiError
     */
    public initiateSftpExport(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/export',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * endpoint to retrieve current banner messages to be shown in app
     * @returns BannerMessage Success
     * @throws ApiError
     */
    public getActiveBannerMessages(): CancelablePromise<Array<BannerMessage>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/banners',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * endpoint for btvt admins to save banner messages to be shown in app
     * @returns void
     * @throws ApiError
     */
    public saveBannerMessage({
        requestBody,
        newMessage = false,
    }: {
        requestBody: BannerMessage,
        newMessage?: boolean,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/banners',
            query: {
                'new-message': newMessage,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * endpoint to mark a banner message as deleted in DB
     * @returns string Success
     * @throws ApiError
     */
    public deleteBannerMessage({
        messageId,
    }: {
        messageId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/banners',
            query: {
                'message-id': messageId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Banner Message not found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Initiate refresh
     * @returns void
     * @throws ApiError
     */
    public initiateRefresh(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notifications/notify-open-trips',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Sends generic emails to a targeted internal audience.
     * @returns string Success; All emails were sent
     * @throws ApiError
     */
    public sendGenericEmailNotification({
        requestBody,
    }: {
        requestBody: EmailNotification,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notifications/generic-email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request; The 'to' list and 'message' attributes cannot be null or empty`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error; The following recipients did not have an email dispatched: %s`,
            },
        });
    }

    /**
     * Get LocationData to populate dashboard of user's trips in relation to state thresholds
     * @returns LocationDays OK
     * @throws ApiError
     */
    public getLocationDays({
        personId,
        taxYear,
    }: {
        personId: string,
        taxYear: string,
    }): CancelablePromise<Array<LocationDays>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/trips/table',
            query: {
                'personId': personId,
                'taxYear': taxYear,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List all TripDays by specified status
     * @returns TripDays OK
     * @throws ApiError
     */
    public listTrips({
        tripStatusList,
    }: {
        tripStatusList: Array<string>,
    }): CancelablePromise<TripDays> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/trips/list',
            query: {
                'tripStatusList': tripStatusList,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get date of last update from SFTP server
     * @returns string OK
     * @throws ApiError
     */
    public getLastUpdatedTime(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/trips/last-updated',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get combined list of Staff and Contingent Workers
     * @returns Person OK
     * @throws ApiError
     */
    public getPeople(): CancelablePromise<Array<Person>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Performs a check to validate if user is able to masquerade
     * @returns string OK; user is allowed to masquerade
     * @throws ApiError
     */
    public canUserMasquerade({
        cfaId,
    }: {
        cfaId: string,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/masquerade',
            query: {
                'cfa-id': cfaId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden; user is not allowed to masquerade`,
            },
        });
    }

    /**
     * Get assigned delegates by ID
     * @returns Person OK
     * @throws ApiError
     */
    public getDelegatesForContextUser(): CancelablePromise<Array<Person>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/configurations/delegates',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
