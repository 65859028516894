/**
 * Do not edit directly
 * Generated on Tue Jul 11 2023 16:29:25 GMT-0400 (Eastern Daylight Time)
 */

import * as Icons from './icon-index';

export type BrandIconType = keyof typeof BrandIconMap;

export const BrandIconMap = {
  'activity-tracker': Icons.ActivityTracker,
  'adult-shoes': Icons.AdultShoes,
  airport: Icons.Airport,
  alignment: Icons.Alignment,
  apple: Icons.Apple,
  apron: Icons.Apron,
  authentic: Icons.Authentic,
  avocado: Icons.Avocado,
  backpack: Icons.Backpack,
  badge: Icons.Badge,
  barricade: Icons.Barricade,
  'baseball-bat-1': Icons.BaseballBat1,
  'baseball-bat-2': Icons.BaseballBat2,
  'baseball-field': Icons.BaseballField,
  baseball: Icons.Baseball,
  basket: Icons.Basket,
  'basketball-goal': Icons.BasketballGoal,
  basketball: Icons.Basketball,
  beacon: Icons.Beacon,
  bear: Icons.Bear,
  bell: Icons.Bell,
  bike: Icons.Bike,
  billboard: Icons.Billboard,
  'birthday-balloons': Icons.BirthdayBalloons,
  'birthday-cake': Icons.BirthdayCake,
  book: Icons.Book,
  'bottom-bun-large': Icons.BottomBunLarge,
  'bottom-bun-small': Icons.BottomBunSmall,
  bowl: Icons.Bowl,
  bowties: Icons.Bowties,
  'brand-love': Icons.BrandLove,
  'break-room-plus': Icons.BreakRoomPlus,
  breakfast: Icons.Breakfast,
  'brick-wall': Icons.BrickWall,
  broccollini: Icons.Broccollini,
  brownie: Icons.Brownie,
  building: Icons.Building,
  bulldozer: Icons.Bulldozer,
  'business-lunch': Icons.BusinessLunch,
  calculator: Icons.Calculator,
  calendar: Icons.Calendar,
  camera: Icons.Camera,
  canoe: Icons.Canoe,
  car: Icons.Car,
  'carry-out': Icons.CarryOut,
  'catering-cold': Icons.CateringCold,
  'catering-warm': Icons.CateringWarm,
  catering: Icons.Catering,
  celebration: Icons.Celebration,
  certificate: Icons.Certificate,
  'charging-station': Icons.ChargingStation,
  'check-mark': Icons.CheckMark,
  check: Icons.Check,
  cheese: Icons.Cheese,
  'chicken-bagel': Icons.ChickenBagel,
  'chicken-biscuit': Icons.ChickenBiscuit,
  'chicken-minis': Icons.ChickenMinis,
  'chicken-slider': Icons.ChickenSlider,
  'chicken-strips': Icons.ChickenStrips,
  'classic-chicken': Icons.ClassicChicken,
  classic: Icons.Classic,
  'cleaning-station': Icons.CleaningStation,
  'closed-sign': Icons.ClosedSign,
  cloudy: Icons.Cloudy,
  coffee: Icons.Coffee,
  'cold-brew': Icons.ColdBrew,
  collaboration: Icons.Collaboration,
  'college-campus': Icons.CollegeCampus,
  'color-palette': Icons.ColorPalette,
  'community-table': Icons.CommunityTable,
  community: Icons.Community,
  'compact-car': Icons.CompactCar,
  compass: Icons.Compass,
  'computer-mouse': Icons.ComputerMouse,
  'contactless-payment': Icons.ContactlessPayment,
  contamination: Icons.Contamination,
  conversation: Icons.Conversation,
  convertible: Icons.Convertible,
  cookie: Icons.Cookie,
  coupe: Icons.Coupe,
  'cow-appreciation-day-t-shirt': Icons.CowAppreciationDayTShirt,
  'cow-costume': Icons.CowCostume,
  cow: Icons.Cow,
  'credit-card': Icons.CreditCard,
  'cross-mark': Icons.CrossMark,
  'cross-walk': Icons.CrossWalk,
  crossover: Icons.Crossover,
  'cup-plastic': Icons.CupPlastic,
  'cup-with-logo': Icons.CupWithLogo,
  'cup-with-straw': Icons.CupWithStraw,
  cup: Icons.Cup,
  curbside: Icons.Curbside,
  dairy: Icons.Dairy,
  'delivery-1': Icons.Delivery1,
  'delivery-2': Icons.Delivery2,
  'delivery-3': Icons.Delivery3,
  desktop: Icons.Desktop,
  'difficulty-easy': Icons.DifficultyEasy,
  'difficulty-hard': Icons.DifficultyHard,
  'difficulty-medium': Icons.DifficultyMedium,
  'dine-in': Icons.DineIn,
  dinner: Icons.Dinner,
  disco: Icons.Disco,
  'divided-lanes': Icons.DividedLanes,
  document: Icons.Document,
  'dog-1': Icons.Dog1,
  'dog-10': Icons.Dog10,
  'dog-11': Icons.Dog11,
  'dog-12': Icons.Dog12,
  'dog-2': Icons.Dog2,
  'dog-3': Icons.Dog3,
  'dog-4': Icons.Dog4,
  'dog-5': Icons.Dog5,
  'dog-6': Icons.Dog6,
  'dog-7': Icons.Dog7,
  'dog-8': Icons.Dog8,
  'dog-9': Icons.Dog9,
  'double-pickles': Icons.DoublePickles,
  down: Icons.Down,
  'drive-thru-1': Icons.DriveThru1,
  'drive-thru-2': Icons.DriveThru2,
  drum: Icons.Drum,
  dumbbell: Icons.Dumbbell,
  'ear-buds': Icons.EarBuds,
  'egg-white-grill': Icons.EggWhiteGrill,
  eggs: Icons.Eggs,
  elevator: Icons.Elevator,
  email: Icons.Email,
  emergency: Icons.Emergency,
  'entrance-or-exit': Icons.EntranceOrExit,
  entree: Icons.Entree,
  'filet-large': Icons.FiletLarge,
  'filet-small': Icons.FiletSmall,
  'fire-exit': Icons.FireExit,
  flag: Icons.Flag,
  'flame-1': Icons.Flame1,
  'flame-2': Icons.Flame2,
  'flame-3': Icons.Flame3,
  flashlight: Icons.Flashlight,
  'flower-vase-1': Icons.FlowerVase1,
  'flower-vase-2': Icons.FlowerVase2,
  'foam-finger': Icons.FoamFinger,
  fog: Icons.Fog,
  'food-truck': Icons.FoodTruck,
  'football-1': Icons.Football1,
  'football-2': Icons.Football2,
  'fork-and-knife': Icons.ForkAndKnife,
  'front-door': Icons.FrontDoor,
  'frosted-lime': Icons.FrostedLime,
  'fruit-cup': Icons.FruitCup,
  gardening: Icons.Gardening,
  gear: Icons.Gear,
  'gift-card-1': Icons.GiftCard1,
  'gift-card-2': Icons.GiftCard2,
  gift: Icons.Gift,
  'globe-2': Icons.Globe2,
  globe: Icons.Globe,
  'goal-post-2-1': Icons.GoalPost21,
  'goal-post-2': Icons.GoalPost2,
  'golden-rule': Icons.GoldenRule,
  'golf-cart': Icons.GolfCart,
  golf: Icons.Golf,
  graduation: Icons.Graduation,
  'graph-1': Icons.Graph1,
  'graph-2': Icons.Graph2,
  gravy: Icons.Gravy,
  'grilled-chicken-club': Icons.GrilledChickenClub,
  'grilled-chicken': Icons.GrilledChicken,
  growth: Icons.Growth,
  guitar: Icons.Guitar,
  'hammer-and-wrench': Icons.HammerAndWrench,
  hammer: Icons.Hammer,
  'hand-sanitizer': Icons.HandSanitizer,
  handshake: Icons.Handshake,
  handwashing: Icons.Handwashing,
  hardhat: Icons.Hardhat,
  'hash-browns': Icons.HashBrowns,
  headset: Icons.Headset,
  heart: Icons.Heart,
  helmet: Icons.Helmet,
  helmets: Icons.Helmets,
  home: Icons.Home,
  hospital: Icons.Hospital,
  hurricane: Icons.Hurricane,
  icecream: Icons.Icecream,
  'in-line': Icons.InLine,
  innovative: Icons.Innovative,
  intentional: Icons.Intentional,
  international: Icons.International,
  ipos: Icons.Ipos,
  jalapeno: Icons.Jalapeno,
  jersey: Icons.Jersey,
  'jump-rope': Icons.JumpRope,
  'kale-crunch': Icons.KaleCrunch,
  'kettle-bell': Icons.KettleBell,
  keyboard: Icons.Keyboard,
  'kid-shoes': Icons.KidShoes,
  'kid-toy-car': Icons.KidToyCar,
  'kids-and-family': Icons.KidsAndFamily,
  'kids-meal': Icons.KidsMeal,
  kps: Icons.Kps,
  ladder: Icons.Ladder,
  landfill: Icons.Landfill,
  laptop: Icons.Laptop,
  'late-night-snack': Icons.LateNightSnack,
  left: Icons.Left,
  lemon: Icons.Lemon,
  letter: Icons.Letter,
  'light-bulb': Icons.LightBulb,
  lock: Icons.Lock,
  'lost-and-found': Icons.LostAndFound,
  'lunch-dinner': Icons.LunchDinner,
  'lunch-n-learn': Icons.LunchNLearn,
  'mac-and-cheese': Icons.MacAndCheese,
  'mall-restaurant-and-adp': Icons.MallRestaurantAndAdp,
  mall: Icons.Mall,
  man: Icons.Man,
  map: Icons.Map,
  marriage: Icons.Marriage,
  massage: Icons.Massage,
  'meal-kits': Icons.MealKits,
  medal: Icons.Medal,
  'medical-mask': Icons.MedicalMask,
  metronome: Icons.Metronome,
  microphone: Icons.Microphone,
  milk: Icons.Milk,
  milkshake: Icons.Milkshake,
  'mobile-dine-in': Icons.MobileDineIn,
  'mobile-phone': Icons.MobilePhone,
  money: Icons.Money,
  moped: Icons.Moped,
  'mothers-room': Icons.MothersRoom,
  'motorcycle-1': Icons.Motorcycle1,
  'motorcycle-2': Icons.Motorcycle2,
  mountains: Icons.Mountains,
  mouse: Icons.Mouse,
  'movie-reel': Icons.MovieReel,
  'music-note': Icons.MusicNote,
  nametag: Icons.Nametag,
  'nametags-in-spanish': Icons.NametagsInSpanish,
  nametags: Icons.Nametags,
  network: Icons.Network,
  'nuggets-packaging': Icons.NuggetsPackaging,
  nuggets: Icons.Nuggets,
  nuts: Icons.Nuts,
  office: Icons.Office,
  oil: Icons.Oil,
  'open-sign': Icons.OpenSign,
  outdoors: Icons.Outdoors,
  'outside-dining': Icons.OutsideDining,
  pan: Icons.Pan,
  'parachuting-cow': Icons.ParachutingCow,
  park: Icons.Park,
  parking: Icons.Parking,
  'partly-cloudy-day': Icons.PartlyCloudyDay,
  'partly-cloudy-night': Icons.PartlyCloudyNight,
  'party-hat': Icons.PartyHat,
  peach: Icons.Peach,
  'peppermint-milkshake': Icons.PeppermintMilkshake,
  photo: Icons.Photo,
  'pickle-left': Icons.PickleLeft,
  'pickle-right': Icons.PickleRight,
  'picnic-table': Icons.PicnicTable,
  polo: Icons.Polo,
  potatoes: Icons.Potatoes,
  'presenting-data': Icons.PresentingData,
  'printer-plus': Icons.PrinterPlus,
  printer: Icons.Printer,
  radio: Icons.Radio,
  rain: Icons.Rain,
  'razor-and-screwdriver': Icons.RazorAndScrewdriver,
  receipt: Icons.Receipt,
  'receiving-water': Icons.ReceivingWater,
  recharge: Icons.Recharge,
  recycling: Icons.Recycling,
  'red-couch': Icons.RedCouch,
  restaurant: Icons.Restaurant,
  restrooms: Icons.Restrooms,
  'ribbon-banner': Icons.RibbonBanner,
  right: Icons.Right,
  roach: Icons.Roach,
  'robot-1': Icons.Robot1,
  'robot-2': Icons.Robot2,
  running: Icons.Running,
  'safety-cone': Icons.SafetyCone,
  'safety-vest': Icons.SafetyVest,
  salt: Icons.Salt,
  'sandwich-drink': Icons.SandwichDrink,
  'sandwich-fries-drink-1': Icons.SandwichFriesDrink1,
  'sandwich-fries-drink-2': Icons.SandwichFriesDrink2,
  'sandwich-fries': Icons.SandwichFries,
  'santa-hat': Icons.SantaHat,
  'sauce-bottle': Icons.SauceBottle,
  'sauce-packet': Icons.SaucePacket,
  savings: Icons.Savings,
  scale: Icons.Scale,
  scheduling: Icons.Scheduling,
  'school-bus': Icons.SchoolBus,
  school: Icons.School,
  scoreboard: Icons.Scoreboard,
  'scramble-bowl': Icons.ScrambleBowl,
  'scramble-burrito': Icons.ScrambleBurrito,
  'secure-device': Icons.SecureDevice,
  sedan: Icons.Sedan,
  'shopping-bags': Icons.ShoppingBags,
  shot: Icons.Shot,
  'shuttle-bus': Icons.ShuttleBus,
  'skill-saw': Icons.SkillSaw,
  sleet: Icons.Sleet,
  snow: Icons.Snow,
  snowman: Icons.Snowman,
  soccer: Icons.Soccer,
  soup: Icons.Soup,
  soy: Icons.Soy,
  specialty: Icons.Specialty,
  'spicy-2': Icons.Spicy2,
  spicy: Icons.Spicy,
  spin: Icons.Spin,
  spoon: Icons.Spoon,
  'sports-car': Icons.SportsCar,
  stadium: Icons.Stadium,
  stairs: Icons.Stairs,
  'stand-alone': Icons.StandAlone,
  stethoscope: Icons.Stethoscope,
  'stop-watch': Icons.StopWatch,
  strawberry: Icons.Strawberry,
  suitcase: Icons.Suitcase,
  suv: Icons.Suv,
  't-shirt': Icons.TShirt,
  'tablet-and-mobile-phone': Icons.TabletAndMobilePhone,
  tablet: Icons.Tablet,
  'team-member': Icons.TeamMember,
  teammates: Icons.Teammates,
  'tent-1': Icons.Tent1,
  'tent-2': Icons.Tent2,
  test: Icons.Test,
  'three-lanes': Icons.ThreeLanes,
  ties: Icons.Ties,
  'time-temperature': Icons.TimeTemperature,
  'top-bun-large': Icons.TopBunLarge,
  'top-bun-small': Icons.TopBunSmall,
  tornado: Icons.Tornado,
  treadmill: Icons.Treadmill,
  tree: Icons.Tree,
  trophy: Icons.Trophy,
  'truck-1': Icons.Truck1,
  'truck-2': Icons.Truck2,
  'truck-mobile-kitchen-1': Icons.TruckMobileKitchen1,
  'truck-mobile-kitchen-2': Icons.TruckMobileKitchen2,
  'tv-1': Icons.Tv1,
  'tv-2': Icons.Tv2,
  'two-lanes': Icons.TwoLanes,
  ukulele: Icons.Ukulele,
  umbrella: Icons.Umbrella,
  unicorn: Icons.Unicorn,
  'up-cycling': Icons.UpCycling,
  up: Icons.Up,
  'van-1': Icons.Van1,
  'van-2': Icons.Van2,
  'video-camera': Icons.VideoCamera,
  voice: Icons.Voice,
  'waffle-fries-1': Icons.WaffleFries1,
  'waffle-fries-2': Icons.WaffleFries2,
  'waffle-potato-chips': Icons.WafflePotatoChips,
  'water-drop': Icons.WaterDrop,
  wedge: Icons.Wedge,
  wheat: Icons.Wheat,
  'wheelchair-accessible-man': Icons.WheelchairAccessibleMan,
  'wheelchair-accessible-woman': Icons.WheelchairAccessibleWoman,
  wheelchair: Icons.Wheelchair,
  whistle: Icons.Whistle,
  wind: Icons.Wind,
  woman: Icons.Woman,
  wrap: Icons.Wrap,
  yoga: Icons.Yoga,
};
