/**
 * Do not edit directly
 * Generated on Tue Jul 11 2023 16:31:59 GMT-0400 (Eastern Daylight Time)
 */

import * as Icons from './icon-index';

export type IconType = keyof typeof IconMap;

export const IconMap = {
  'actions-horizontal': Icons.ActionsHorizontal,
  'actions-vertical': Icons.ActionsVertical,
  'address-book': Icons.AddressBook,
  'adjustments-horizontal': Icons.AdjustmentsHorizontal,
  alarm: Icons.Alarm,
  'alert-circle': Icons.AlertCircle,
  antenna: Icons.Antenna,
  archive: Icons.Archive,
  'arrow-bar-to-down': Icons.ArrowBarToDown,
  'arrow-bar-to-up': Icons.ArrowBarToUp,
  'arrow-narrow-down': Icons.ArrowNarrowDown,
  'arrow-narrow-up': Icons.ArrowNarrowUp,
  'arrows-maximize': Icons.ArrowsMaximize,
  'arrows-move': Icons.ArrowsMove,
  award: Icons.Award,
  bell: Icons.Bell,
  book: Icons.Book,
  'bookmark-filled': Icons.BookmarkFilled,
  'bookmark-outline': Icons.BookmarkOutline,
  books: Icons.Books,
  briefcase: Icons.Briefcase,
  building: Icons.Building,
  bulb: Icons.Bulb,
  calendar: Icons.Calendar,
  camera: Icons.Camera,
  car: Icons.Car,
  celebrate: Icons.Celebrate,
  'chart-bar': Icons.ChartBar,
  check: Icons.Check,
  checklist: Icons.Checklist,
  'chevron-down': Icons.ChevronDown,
  'chevron-left': Icons.ChevronLeft,
  'chevron-right': Icons.ChevronRight,
  'chevron-up': Icons.ChevronUp,
  'circle-cross-filled': Icons.CircleCrossFilled,
  'circle-cross-outline': Icons.CircleCrossOutline,
  'circle-minus': Icons.CircleMinus,
  'circle-plus': Icons.CirclePlus,
  'clipboard-text': Icons.ClipboardText,
  clock: Icons.Clock,
  close: Icons.Close,
  'cloud-download': Icons.CloudDownload,
  'cloud-upload': Icons.CloudUpload,
  cloud: Icons.Cloud,
  'coin-filled': Icons.CoinFilled,
  coin: Icons.Coin,
  copy: Icons.Copy,
  'corner-down-right': Icons.CornerDownRight,
  create: Icons.Create,
  'credit-card': Icons.CreditCard,
  dash: Icons.Dash,
  'device-desktop': Icons.DeviceDesktop,
  'device-laptop': Icons.DeviceLaptop,
  'device-mobile': Icons.DeviceMobile,
  'discount-filled': Icons.DiscountFilled,
  discount: Icons.Discount,
  download: Icons.Download,
  edit: Icons.Edit,
  'error-filled': Icons.ErrorFilled,
  'error-outline': Icons.ErrorOutline,
  'external-link': Icons.ExternalLink,
  'file-certificate': Icons.FileCertificate,
  'file-search': Icons.FileSearch,
  files: Icons.Files,
  'filter-x': Icons.FilterX,
  filter: Icons.Filter,
  'gamepad-2': Icons.Gamepad2,
  'grip-vertical': Icons.GripVertical,
  'heart-filled': Icons.HeartFilled,
  'heart-rate-monitor': Icons.HeartRateMonitor,
  heart: Icons.Heart,
  hide: Icons.Hide,
  home: Icons.Home,
  'info-filled': Icons.InfoFilled,
  'info-outline': Icons.InfoOutline,
  link: Icons.Link,
  'list-details': Icons.ListDetails,
  list: Icons.List,
  'lock-check': Icons.LockCheck,
  'lock-open': Icons.LockOpen,
  lock: Icons.Lock,
  logout: Icons.Logout,
  mail: Icons.Mail,
  'menu-2': Icons.Menu2,
  menu: Icons.Menu,
  message: Icons.Message,
  microphone: Icons.Microphone,
  money: Icons.Money,
  movie: Icons.Movie,
  'new-section': Icons.NewSection,
  note: Icons.Note,
  notes: Icons.Notes,
  paperclip: Icons.Paperclip,
  pencil: Icons.Pencil,
  photo: Icons.Photo,
  'pin-filled': Icons.PinFilled,
  'pin-outline': Icons.PinOutline,
  play: Icons.Play,
  plus: Icons.Plus,
  print: Icons.Print,
  refresh: Icons.Refresh,
  'report-search': Icons.ReportSearch,
  search: Icons.Search,
  send: Icons.Send,
  settings: Icons.Settings,
  shapes: Icons.Shapes,
  'shopping-cart': Icons.ShoppingCart,
  show: Icons.Show,
  'sidebar-left': Icons.SidebarLeft,
  'sidebar-right': Icons.SidebarRight,
  sort: Icons.Sort,
  store: Icons.Store,
  'success-filled': Icons.SuccessFilled,
  'success-outline': Icons.SuccessOutline,
  'switch-2': Icons.Switch2,
  'switch-vertical': Icons.SwitchVertical,
  'tools-kitchen-2': Icons.ToolsKitchen2,
  trash: Icons.Trash,
  undo: Icons.Undo,
  'user-minus': Icons.UserMinus,
  'user-plus': Icons.UserPlus,
  user: Icons.User,
  'warning-filled': Icons.WarningFilled,
  'warning-outline': Icons.WarningOutline,
  world: Icons.World,
  wrench: Icons.Wrench,
  'zoom-in': Icons.ZoomIn,
  'zoom-out': Icons.ZoomOut,
};
