import React, { useEffect, useState, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import scrollIntoView from 'scroll-into-view-if-needed';
import {
  useControlledState,
  useCSSPrefix,
  useForwardedRef,
  useDescriptiveText,
} from '../internal/hooks';
import { FormFieldProps } from '../internal/interfaces';
import { getBrowserLocaleCode } from '../internal/utils/getBrowserLocaleCode';
import { clampNumber } from '../internal/utils/clamp';
import { HelperText } from '../internal/components/HelperText';
import { InputLabel } from '../internal/components/InputLabel';
import { BasePopper } from '../internal/components/BasePopper';
import { Key } from '../internal/enums/Key';
import './TimePicker.scss';
import {
  dateToTimeString,
  timeStringToDate,
  getTimeFragments,
  isTimeEqual,
  dateIsValid,
  getValidLocale,
  getInvalidDate,
} from '../internal/utils/DateTimeUtils';
import { IconButton } from '../IconButton';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

// this component does not extend html props, as many html event handlers and aria attributes will not work expectedly
export interface TimePickerProps extends Omit<FormFieldProps, 'placeholder'> {
  /**
   * Specify the time interval in minutes for the values in the dropdown
   */
  step?: number;
  /**
   * Optionally, specify a locale code for i18n to change the input/display format. Defaults to the browser's locale or `en-US`
   */
  locale?: string;
  /**
   * If uncontrolled, specify an initial value
   */
  defaultValue?: Date | null;
  /**
   * If controlled, specify the value to display
   */
  value?: Date | null;
  /**
   * Optionally, specify a function to be called when the input value changes
   */
  onChange?: (value: Date | null) => void;
  /**
   * Optionally, provide a callback to disabled specific times
   */
  disabledTimes?: (time: Date) => boolean;
}

const placeholderDate = new Date(0, 0, 0, 0);

export const TimePicker = React.forwardRef<HTMLInputElement, TimePickerProps>(
  (
    {
      step = 15,
      className,
      style,
      disabled = false,
      readOnly = false,
      defaultValue = null,
      value: valueProp,
      onChange: onChangeProp,
      disabledTimes,
      required = false,
      hideRequiredStyle = false,
      id: idProp,
      label,
      helperText,
      errorText,
      successText,
      fullWidth,
      locale = getBrowserLocaleCode(),
      margin = false,
      compact = false,
      'data-testid': dataTestId,
      ...props
    }: TimePickerProps,
    ref
  ) => {
    const [value, setValue] = useControlledState(
      defaultValue,
      valueProp,
      'TimePicker'
    );
    const localeCode = getValidLocale(locale);
    // Tracks user-submitted string input
    const [inputValue, setInputValue] = useState('');
    // Tracks whether to display error styling when there is an invalid date
    const [isFormatted, setIsFormatted] = useState(false);
    // Tracks time options from dropdown list
    const [navigatedTime, setNavigatedTime] = useState<Date | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    // Tracks whether icon button is in focus
    const [isIconButtonFocused, setIsIconButtonFocused] = useState(false);
    const iconBtnRef = useRef<HTMLButtonElement>(null);
    const [referenceElement, setReferenceElement] =
      useState<HTMLElement | null>(null);

    const onChangeFiredRef = useRef(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const internalErrorText =
      !isFormatted && !!inputValue && !errorText ? 'Invalid time' : undefined;

    const errorTextToUse = errorText || internalErrorText;

    const { status } = useDescriptiveText(
      errorTextToUse,
      successText,
      helperText
    );

    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const disabledOrReadonly = disabled || readOnly;

    const getOptionId = (time: Date) => {
      return `${id}-${time.toISOString().split('T')[1]}`;
    };

    const timeFragments = useMemo(() => {
      return getTimeFragments(step).map((time) => ({
        dateTime: time,
        itemId: getOptionId(time),
        label: dateToTimeString(time, localeCode),
      }));
    }, [step, localeCode, id]);

    const close = (shouldFocus: boolean = false) => {
      shouldFocus && inputRef.current?.focus();
      setIsOpen(false);
    };

    useEffect(() => {
      // If value changed outside of onChange AND value is either empty (falsy) or valid, then update the input display
      if (
        !onChangeFiredRef.current &&
        value !== undefined &&
        (!value || dateIsValid(value))
      ) {
        setInputValue(dateToTimeString(value, localeCode));
        setNavigatedTime(value);
        setIsFormatted(true);
      }
      onChangeFiredRef.current = false;
    }, [value, localeCode]);

    // Scrolls the list of available times when the highlighted time overflows beyond the popup.
    useEffect(() => {
      if (navigatedTime && dateIsValid(navigatedTime)) {
        const navigatedItem = document.getElementById(
          getOptionId(navigatedTime)
        );
        if (navigatedItem && isOpen) {
          scrollIntoView(navigatedItem, {
            scrollMode: 'if-needed',
            block: 'nearest',
            boundary: navigatedItem.parentElement,
          });
        }
      }
    }, [navigatedTime, isOpen]);

    const isDateInList = (enteredDate: Date) => {
      return timeFragments.some((time) =>
        isTimeEqual(time.dateTime, enteredDate)
      );
    };

    const isTimeDisabled = (time?: Date | null) =>
      !!time && disabledTimes && disabledTimes(time);

    const getNextTime = () => {
      let i = 1;
      let nextTime = getFragmentByIndex(getCurrentIndex() + 1);
      let nextTimeDisabled = isTimeDisabled(nextTime);

      const lastFragmentIndex = timeFragments.length - 1;

      while (
        nextTimeDisabled &&
        nextTime.valueOf() < timeFragments[lastFragmentIndex].dateTime.valueOf()
      ) {
        i++;
        nextTime = getFragmentByIndex(getCurrentIndex() + i);
        nextTimeDisabled = isTimeDisabled(nextTime);
      }

      if (!nextTimeDisabled) {
        return nextTime;
      }
    };

    const getPreviousTime = () => {
      let i = 1;
      let previousTime = getFragmentByIndex(getCurrentIndex() - 1);
      let previousTimeDisabled = isTimeDisabled(previousTime);

      while (
        previousTimeDisabled &&
        previousTime.valueOf() > timeFragments[0].dateTime.valueOf()
      ) {
        i++;
        previousTime = getFragmentByIndex(getCurrentIndex() - i);
        previousTimeDisabled = isTimeDisabled(previousTime);
      }

      if (!previousTimeDisabled) {
        return previousTime;
      }
    };

    const getCurrentIndex = () => {
      return timeFragments.findIndex((elem) =>
        isTimeEqual(elem.dateTime, navigatedTime)
      );
    };

    const getFragmentByIndex = (index: number) => {
      // eslint-disable-next-line no-param-reassign
      index = clampNumber(index, 0, timeFragments.length - 1);
      return timeFragments[index].dateTime;
    };

    const selectTime = (selectedTime: Date | null) => {
      setNavigatedTime(selectedTime);

      setInputValue(dateToTimeString(selectedTime, localeCode));
      setIsFormatted(true);

      const prevValue = value;
      // Uncontrolled, without value, store the value
      if (!valueProp && valueProp !== null) {
        setValue(selectedTime);
      }
      // Controlled, with onChange prop
      if (onChangeProp && selectedTime !== prevValue) {
        onChangeProp(selectedTime);
      }
      close(true);
    };

    const handleInputKeyDown = (e: React.KeyboardEvent) => {
      if (readOnly) {
        return;
      }

      if (isOpen) {
        if ([Key.ArrowDown, Key.ArrowUp, Key.Enter].includes(e.key as Key)) {
          e.preventDefault();
        }
        if (e.key === Key.ArrowUp) {
          const previousTime = getPreviousTime();
          if (previousTime) setNavigatedTime(previousTime);
        }
        if (e.key === Key.ArrowDown) {
          const nextTime = getNextTime();
          if (nextTime) setNavigatedTime(nextTime);
        }
        if (e.key === Key.Enter || e.key === Key.Space) {
          e.preventDefault();
          if (!disabledTimes || !disabledTimes(navigatedTime!)) {
            return selectTime(navigatedTime);
          }
        }
        if (e.key === Key.Tab) {
          close(true);
        }
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const strInput = e.target.value;
      // Track when value is changed through input: keep value and input in sync
      onChangeFiredRef.current = true;

      // Maintain internal state of user-typed string input
      setInputValue(strInput);

      // Convert user-typed string input to a Date
      let result = strInput ? timeStringToDate(strInput, localeCode) : null;

      // Check if time is disabled
      const timeIsDisabled = isTimeDisabled(result);

      // If time is disabled, make value match invalid times returned from timeStringToDate function
      result = timeIsDisabled ? getInvalidDate() : result;

      // Check if converted result is either a valid or invalid date
      setIsFormatted(dateIsValid(result));

      // Set navigation highlight in the dropdown to match the user-inputted time (if it exists)
      setNavigatedTime(!!result && isDateInList(result) ? result : null);

      // Handle un-controlled state
      if (valueProp === undefined) {
        setValue(result);
      }

      // Handle controlled state
      onChangeProp?.(result);

      // Close dropdown (if open) when typing
      isOpen && setIsOpen(false);
    };

    const isSelected = (time: Date) => {
      return isTimeEqual(time, value);
    };

    const isHighlighted = (time: Date) => {
      if (!navigatedTime) return false;
      return isTimeEqual(time, navigatedTime);
    };

    const inputId = `${id}-input`;

    return (
      <div
        id={id}
        data-testid={dataTestId}
        style={style}
        className={clsx([
          `${cssPrefix}-timepicker-wrapper`,
          margin && 'timepicker-margin',
          fullWidth && 'full-width',
          className,
        ])}
      >
        {label && (
          <InputLabel
            htmlFor={inputId}
            disabled={disabled}
            required={required}
            hideRequiredStyle={hideRequiredStyle}
          >
            {label}
          </InputLabel>
        )}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={clsx([
            `${cssPrefix}-timepicker-input-wrapper`,
            !isIconButtonFocused && 'user-focused-within',
            status,
            disabled && 'disabled',
            readOnly && 'read-only',
            compact && 'compact',
          ])}
          ref={setReferenceElement}
          onKeyDown={handleInputKeyDown}
        >
          {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
          <input
            id={inputId}
            className={clsx([`${cssPrefix}-timepicker-input`])}
            ref={useForwardedRef(ref, inputRef)}
            autoComplete="off"
            value={inputValue === null ? '' : inputValue}
            placeholder={
              !disabledOrReadonly
                ? dateToTimeString(placeholderDate, localeCode)
                : undefined
            }
            onChange={handleChange}
            readOnly={readOnly}
            disabled={disabled}
            aria-haspopup="listbox"
            {...props}
            required={required}
          />
          <IconButton
            ref={iconBtnRef}
            size="xs"
            color="default"
            className={`${cssPrefix}-timepicker-button`}
            disabled={disabledOrReadonly}
            aria-label="Open time list"
            aria-expanded={isOpen}
            onFocus={() => setIsIconButtonFocused(true)}
            onBlur={() => setIsIconButtonFocused(false)}
          >
            <Icon icon="clock" />
          </IconButton>
        </div>
        <HelperText
          errorText={errorTextToUse}
          successText={successText}
          helperText={helperText}
          id={`${id}-helper-text`}
        />
        <BasePopper
          show={isOpen}
          setShow={setIsOpen}
          placement="bottom-end"
          referenceElement={referenceElement}
          showOnElement={iconBtnRef.current}
          showOnElementEvents={!disabledOrReadonly ? ['click'] : undefined}
        >
          <ul role="listbox" className={`${cssPrefix}-timepicker-list`}>
            {timeFragments.map(({ dateTime, itemId, label: tfLabel }) => {
              const isItemSelected = isSelected(dateTime);
              const isItemHighlighted = isHighlighted(dateTime);
              const isItemDisabled = isTimeDisabled(dateTime);
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li
                  key={itemId}
                  role="option"
                  id={itemId}
                  className={clsx([
                    `${cssPrefix}-timepicker-list-item`,
                    isItemHighlighted && 'highlighted',
                    isItemSelected && 'selected',
                    isItemDisabled && 'disabled-time',
                  ])}
                  onMouseEnter={
                    !isItemDisabled
                      ? () => setNavigatedTime(dateTime)
                      : undefined
                  }
                  onClick={
                    !isItemDisabled ? () => selectTime(dateTime) : undefined
                  }
                  aria-selected={isItemSelected}
                >
                  <Typography variant="body1">{tfLabel}</Typography>
                </li>
              );
            })}
          </ul>
        </BasePopper>
      </div>
    );
  }
);
