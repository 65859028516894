import React, { useContext } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { DrawerContext } from 'Drawer';
import { Colors } from '../internal/types';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks';
import { useMediaQuery } from '../useMediaQuery';
import { useBreakpoints } from '../useBreakpoints';
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicRef,
} from '../internal/types/Polymorphic';
import './NavLink.scss';
import { TopNavigationContext } from '../TopNavigation';
import { SideNavigationContext } from '../SideNavigation';

export type NavLinkVariant =
  | 'topnavigation-link'
  | 'sidenavigation-link'
  | 'drawer-link';

export interface INavLink
  extends React.HTMLAttributes<HTMLAnchorElement>,
    CommonProps {
  /**
   * Specify the variant of navlink, from the following list of variants:
   */
  variant: NavLinkVariant;
  /**
   * Optionally specify the 'active' link and apply the 'active' styling
   */
  isActive?: boolean;
  /**
   * Optionally specify the color of the active navlink from the following list of colors:
   */
  color?: Colors;
}

// only allowing use of icon if variant is 'sidenavigation-link': https://www.benmvp.com/blog/conditional-react-props-typescript/
type ConditionalProps =
  | { variant: 'topnavigation-link'; icon?: never }
  | { variant: 'drawer-link'; icon?: React.ReactNode }
  | {
      variant: 'sidenavigation-link';
      /**
       * Specify an element to render as the icon. Required if `variant="sidenavigation-link"`, optional otherwise.
       */
      icon: React.ReactNode;
    };

const defaultElement = 'a';

type CombinedProps = ConditionalProps & INavLink;

export const NavLink: PolymorphicForwardRefExoticComponent<
  CombinedProps,
  typeof defaultElement
> = React.forwardRef(function NavLink<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    variant = 'topnavigation-link',
    className,
    isActive,
    children,
    icon,
    color = 'primary',
    id: idProp,
    ...rest
  }: PolymorphicPropsWithoutRef<CombinedProps, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = as || defaultElement;

  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);
  const breakpoints = useBreakpoints();
  const isLargeOrHigher = useMediaQuery(breakpoints.up('lg'));
  const isBelowLarge = useMediaQuery(breakpoints.down('lg'));

  const { color: topNavColor } = useContext(TopNavigationContext) || {};
  const { color: sideNavColor } = useContext(SideNavigationContext) || {};
  const { anchor: drawerNavAnchor } = useContext(DrawerContext) || {};

  const colorToUse = (topNavColor || sideNavColor) ?? color;

  const role =
    Component === 'button'
      ? 'button'
      : Component === 'a' || typeof Component === 'object'
      ? 'link'
      : null;

  const isSideNavVariant = variant === 'sidenavigation-link';
  const isTopNavVariant = variant === 'topnavigation-link';
  const isDrawerVariant = variant === 'drawer-link';

  if (isSideNavVariant && !icon) {
    console.error(
      [
        'CDS: An instance of <NavLink variant="sidenavigation-link /> needs an `icon` prop for when SideNavigation is used on smaller screen sizes.',
        'Please supply the `icon` prop.',
      ].join(' ')
    );
  }

  return (
    <Component
      {...rest}
      ref={ref}
      id={id}
      className={clsx([
        `${cssPrefix}-navlink`,
        {
          'navlink-bottom': isBelowLarge,
          'navlink-bottom-more': isLargeOrHigher && isTopNavVariant,
          [`navlink-${variant.replace('-link', '')}-color-${colorToUse}`]:
            colorToUse,
        },
        variant,
        isActive && 'active',
        className,
      ])}
      role={role}
    >
      {isDrawerVariant && drawerNavAnchor === 'right' && (
        <div className="sidenav-border-left" />
      )}
      <div className="navlink-content">
        {(isSideNavVariant || isDrawerVariant) && icon}
        {(!isSideNavVariant || (isSideNavVariant && !isBelowLarge)) && (
          <span
            className={clsx(
              'navlink-children',
              (isSideNavVariant || isDrawerVariant) && icon && 'spacing'
            )}
          >
            {children}
          </span>
        )}
      </div>
      {(isSideNavVariant ||
        (isDrawerVariant && drawerNavAnchor === 'left')) && (
        <div className="sidenav-border-right" />
      )}
    </Component>
  );
});
