import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { DurationLikeObject } from 'luxon';
import {
  parseDate,
  formatDate,
  getDaysInMonth,
  getHeadingText,
  getPlaceholder,
  getWeekdayNames,
  getValidLocale,
  getCurrentDate,
  isSame,
  addTime,
  getUnit,
  subtractTime,
  dateIsValid,
  isToday,
  getInvalidDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../internal/utils/DateTimeUtils';
import { Typography } from '../Typography';
import { FormFieldProps } from '../internal/interfaces';
import {
  useCSSPrefix,
  useControlledState,
  useForwardedRef,
  useDescriptiveText,
} from '../internal/hooks';
import './DatePicker.scss';
import { Key } from '../internal/enums';
import { InputLabel } from '../internal/components/InputLabel';
import { BasePopper } from '../internal/components/BasePopper';
import { TabLoop } from '../internal/components/TabLoop';
import { HelperText } from '../internal/components/HelperText';
import { IconButton } from '../IconButton';
import { Icon } from '../Icon';
import { getBrowserLocaleCode } from '../internal/utils/getBrowserLocaleCode';

const currentDate = getCurrentDate();

// this component does not extend html props, as many html event handlers and aria attributes will not work expectedly
export interface DatePickerProps extends FormFieldProps {
  /**
   * If uncontrolled, specify an initial value
   */
  defaultValue?: Date;
  /**
   * If controlled, specify the value to display
   */
  value?: Date | null;
  /**
   * Optionally, specify a function to be called when the input value changes
   */
  onChange?: (value: Date | null) => void;
  /**
   * Optionally, specify a locale code for i18n to change the input/display format. Defaults to the browser's locale or `en-US`
   */
  locale?: string;
  /**
   * Optionally, provide a callback to disable specified dates
   */
  disabledDates?: (date: Date) => boolean;
}

export const accessibleButtonLabelText = {
  openCalendar: 'Open calendar',
  previousMonth: 'Go to previous month',
  nextMonth: 'Go to next month',
};

export const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      id: idProp,
      'data-testid': dataTestId,
      style,
      className,
      label,
      disabled,
      helperText,
      errorText,
      successText,
      fullWidth,
      readOnly,
      defaultValue,
      value: valueProp,
      onChange: onChangeProp,
      locale = getBrowserLocaleCode(),
      margin = false,
      compact = false,
      required = false,
      hideRequiredStyle = false,
      placeholder,
      disabledDates,
      ...props
    }: DatePickerProps,
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const disabledOrReadonly = disabled || readOnly;

    const [value, setValue] = useControlledState(
      defaultValue,
      valueProp,
      'DatePicker'
    );
    const localeCode = getValidLocale(locale);
    // Tracks user-submitted string input
    const [inputValue, setInputValue] = useState(
      defaultValue ? formatDate(defaultValue, localeCode) : ''
    );
    // Tracks whether to display error styling when there is an invalid date
    const [isFormatted, setIsFormatted] = useState(false);
    // Tracks date options from calendar
    const [navigatedDate, setNavigatedDate] = useState(value ?? currentDate);
    const [isOpen, setIsOpen] = useState(false);
    // Tracks whether icon button is in focus
    const [isIconButtonFocused, setIsIconButtonFocused] = useState(false);
    const iconBtnRef = useRef<HTMLButtonElement>(null);
    const [inputWrapperEle, setInputWrapperEle] =
      useState<HTMLDivElement | null>(null);
    const [daysInMonth, setDaysInMonth] = useState<Date[]>([]);
    const onChangeFiredRef = useRef(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const navigatedDateRef = useRef<HTMLDivElement>(null);
    const calendarWidgetRef = useRef<HTMLInputElement>(null);

    const internalErrorText =
      !isFormatted && !!inputValue && !errorText ? 'Invalid date' : undefined;

    const isDateDisabled = (date: Date) => disabledDates && disabledDates(date);

    const errorTextToUse = errorText || internalErrorText;
    const { status } = useDescriptiveText(
      errorTextToUse,
      successText,
      helperText
    );

    // get days of month on initial render
    useEffect(() => {
      setDaysInMonth(getDaysInMonth(navigatedDate));
    }, []);

    useEffect(() => {
      // If value changed outside of onChange AND value is either empty (falsy) or valid, then update the input display
      if (
        !onChangeFiredRef.current &&
        value !== undefined &&
        (!value || dateIsValid(value))
      ) {
        setInputValue(formatDate(value, localeCode));
        setNavigatedDate(value ?? currentDate);
        setIsFormatted(true);
      }
      onChangeFiredRef.current = false;
    }, [value, localeCode]);

    const close = (shouldFocus: boolean = false) => {
      shouldFocus && inputRef.current?.focus();
      setIsOpen(false);
    };

    const changeMonth = (date: Date) => {
      const currentMonth = getUnit(navigatedDate, 'month');
      const newMonth = getUnit(date, 'month');

      const currentYear = getUnit(navigatedDate, 'year');
      const newYear = getUnit(date, 'year');

      if (currentMonth !== newMonth || currentYear !== newYear) {
        setDaysInMonth(getDaysInMonth(date));
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const dateInput = e.target.value;

      let date = dateInput ? parseDate(e.target.value, localeCode) : null;

      // Check if date is disabled
      const disabledDate = !!date && isDateDisabled(date);

      // If date is disabled, make value match invalid dates from parseDate function
      date = disabledDate ? getInvalidDate() : date;

      onChangeFiredRef.current = true;

      const validDate = dateIsValid(date);

      setInputValue(dateInput);

      // Check if converted result is either a valid or invalid date
      setIsFormatted(validDate);

      if ((date && validDate) || date === null) {
        changeMonth(date ?? currentDate);
        setNavigatedDate(date ?? currentDate);
      }

      // Handle un-controlled state
      if (valueProp === undefined) {
        setValue(date);
      }

      // Handle controlled state
      onChangeProp?.(date);

      // Close dropdown (if open) when typing
      isOpen && setIsOpen(false);
    };

    const selectDate = (date: Date) => {
      changeMonth(date);
      setNavigatedDate(date);
      setInputValue(formatDate(date, localeCode));
      setIsFormatted(true);

      const prevValue = value;
      // Uncontrolled, without value, store the value
      if (!valueProp && valueProp !== null) {
        setValue(date);
      }
      // Controlled, with onChange prop
      if (onChangeProp && date !== prevValue) {
        onChangeProp(date);
      }
      close(true);
    };

    const handleDateClicked = (e: React.MouseEvent, date: Date) => {
      e.stopPropagation();
      selectDate(date);
    };

    const navigate = (date: Date, refocusDate: boolean = false) => {
      changeMonth(date);
      setNavigatedDate(date);
      requestAnimationFrame(() => {
        const targetDate = document.querySelector(
          `.${cssPrefix}-day--${date.getDate()}:not(.outside-month)`
        ) as HTMLElement;
        if (refocusDate) targetDate?.focus();
      });
    };

    const goToPrevious = (
      initialNavigatedDate: Date,
      canNavigateToInitialDate: boolean,
      unit: keyof DurationLikeObject,
      preventNavigation: boolean
    ) => {
      let newNavigatedDate = initialNavigatedDate;
      let canNavigateToNewDate = canNavigateToInitialDate;

      const twoMonthsPast = getLastDayOfMonth(
        subtractTime(navigatedDate, 'months', 2)
      );

      while (
        !canNavigateToNewDate &&
        newNavigatedDate > addTime(twoMonthsPast, unit, 1)
      ) {
        newNavigatedDate = subtractTime(newNavigatedDate, unit, 1);
        canNavigateToNewDate = !isDateDisabled(newNavigatedDate);
      }

      // Should still be able to navigate month to month even if dates are disabled
      if (!preventNavigation) {
        navigate(newNavigatedDate);
      } else if (preventNavigation && canNavigateToNewDate) {
        navigate(newNavigatedDate, true);
      }
    };

    const goToNext = (
      initialNavigatedDate: Date,
      canNavigateToInitialDate: boolean,
      unit: keyof DurationLikeObject,
      preventNavigation: boolean
    ) => {
      let newNavigatedDate = initialNavigatedDate;
      let canNavigateToNewDate = canNavigateToInitialDate;

      const twoMonthsFuture = getFirstDayOfMonth(
        addTime(navigatedDate, 'months', 2)
      );

      while (
        !canNavigateToNewDate &&
        newNavigatedDate < subtractTime(twoMonthsFuture, unit, 1)
      ) {
        newNavigatedDate = addTime(newNavigatedDate, unit, 1);
        canNavigateToNewDate = !isDateDisabled(newNavigatedDate);
      }

      // Should still be able to navigate month to month even if dates are disabled
      if (!preventNavigation) {
        navigate(newNavigatedDate);
      } else if (preventNavigation && canNavigateToNewDate) {
        navigate(newNavigatedDate, true);
      }
    };

    const goToNextDay = () => {
      const newNavigatedDate = addTime(navigatedDate, 'days', 1);
      const canNavigateToDate = !isDateDisabled(newNavigatedDate);

      goToNext(newNavigatedDate, canNavigateToDate, 'days', true);
    };

    const goToNextWeek = () => {
      const newNavigatedDate = addTime(navigatedDate, 'weeks', 1);
      const canNavigateToDate = !isDateDisabled(newNavigatedDate);

      goToNext(newNavigatedDate, canNavigateToDate, 'weeks', true);
    };

    const goToNextMonth = () => {
      const newNavigatedDate = getFirstDayOfMonth(
        addTime(navigatedDate, 'months', 1)
      );
      const canNavigateToDate = !isDateDisabled(newNavigatedDate);
      goToNext(newNavigatedDate, canNavigateToDate, 'days', false);
    };

    const goToPreviousDay = () => {
      const newNavigatedDate = subtractTime(navigatedDate, 'days', 1);
      const canNavigateToDate = !isDateDisabled(newNavigatedDate);

      goToPrevious(newNavigatedDate, canNavigateToDate, 'days', true);
    };

    const goToPreviousWeek = () => {
      const newNavigatedDate = subtractTime(navigatedDate, 'weeks', 1);
      const canNavigateToDate = !isDateDisabled(newNavigatedDate);

      goToPrevious(newNavigatedDate, canNavigateToDate, 'weeks', true);
    };

    const goToPreviousMonth = () => {
      const newNavigatedDate = getLastDayOfMonth(
        subtractTime(navigatedDate, 'months', 1)
      );
      const canNavigateToDate = !isDateDisabled(newNavigatedDate);

      goToPrevious(newNavigatedDate, canNavigateToDate, 'days', false);
    };

    const goToPreviousYear = () =>
      navigate(subtractTime(navigatedDate, 'years', 1));
    const goToNextYear = () => navigate(addTime(navigatedDate, 'years', 1));
    const handleKeyDown = (e: React.KeyboardEvent) => {
      const { key } = e;

      if (readOnly) {
        return;
      }

      if (isOpen) {
        if (
          [
            Key.ArrowLeft,
            Key.ArrowRight,
            Key.ArrowUp,
            Key.ArrowDown,
            Key.PageUp,
            Key.PageDown,
            Key.Home,
            Key.End,
            Key.Enter,
          ].includes(e.key as Key)
        ) {
          e.preventDefault();
        }

        if (key === Key.ArrowLeft) {
          goToPreviousDay();
        }
        if (key === Key.ArrowRight) {
          goToNextDay();
        }
        if (key === Key.ArrowUp) {
          goToPreviousWeek();
        }
        if (key === Key.ArrowDown) {
          goToNextWeek();
        }
        if (key === Key.PageUp) {
          goToPreviousMonth();
        }
        if (key === Key.PageDown) {
          goToNextMonth();
        }
        if (key === Key.Home) {
          goToPreviousYear();
        }
        if (key === Key.End) {
          goToNextYear();
        }
        if (key === Key.Enter || key === Key.Space) {
          e.preventDefault();
          selectDate(navigatedDate);
        }
      }
    };

    const inputId = `${id}-input`;

    return (
      <div
        id={id}
        data-testid={dataTestId}
        style={style}
        className={clsx([
          `${cssPrefix}-datepicker-wrapper`,
          margin && 'datepicker-margin',
          fullWidth && 'full-width',
          className,
        ])}
      >
        {label && (
          <InputLabel
            htmlFor={inputId}
            disabled={disabled}
            required={required}
            hideRequiredStyle={hideRequiredStyle}
          >
            {label}
          </InputLabel>
        )}

        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={clsx([
            `${cssPrefix}-datepicker-input-wrapper`,
            !isIconButtonFocused && 'user-focused-within',
            disabled && 'disabled',
            readOnly && 'read-only',
            status,
            compact && 'compact',
          ])}
          ref={setInputWrapperEle}
        >
          {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
          <input
            {...props}
            type="text"
            id={inputId}
            className={`${cssPrefix}-datepicker-input`}
            disabled={disabled}
            readOnly={readOnly}
            ref={useForwardedRef(ref, inputRef)}
            autoComplete="off"
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder || getPlaceholder(localeCode)}
            aria-haspopup="listbox"
            required={required}
          />
          <IconButton
            ref={iconBtnRef}
            size="xs"
            color="default"
            className={`${cssPrefix}-datepicker-button`}
            disabled={disabledOrReadonly}
            aria-label={accessibleButtonLabelText.openCalendar}
            aria-expanded={isOpen}
            onFocus={() => setIsIconButtonFocused(true)}
            onBlur={() => setIsIconButtonFocused(false)}
          >
            <Icon icon="calendar" />
          </IconButton>
        </div>
        <HelperText
          errorText={errorTextToUse}
          successText={successText}
          helperText={helperText}
          id={`${id}-helper-text`}
        />
        <BasePopper
          show={isOpen}
          setShow={setIsOpen}
          placement="bottom-end"
          referenceElement={inputWrapperEle}
          showOnElement={iconBtnRef.current}
          showOnElementEvents={!disabledOrReadonly ? ['click'] : undefined}
        >
          <TabLoop
            autoFocus
            autoFocusElementRef={navigatedDateRef}
            enabled={isOpen}
            innerContainerRef={calendarWidgetRef}
          >
            <div role="dialog" ref={calendarWidgetRef}>
              <div className={`${cssPrefix}-datepicker-header`}>
                <IconButton
                  onClick={goToPreviousMonth}
                  aria-label={accessibleButtonLabelText.previousMonth}
                  className={`${cssPrefix}-icon-button`}
                  size="xs"
                  color="default"
                >
                  <Icon icon="chevron-left" />
                </IconButton>
                <Typography variant="body1" fontWeight="bold">
                  {getHeadingText(navigatedDate, localeCode)}
                </Typography>
                <IconButton
                  onClick={goToNextMonth}
                  aria-label={accessibleButtonLabelText.nextMonth}
                  className={`${cssPrefix}-icon-button`}
                  size="xs"
                  color="default"
                >
                  <Icon icon="chevron-right" />
                </IconButton>
              </div>
              <div className={`${cssPrefix}-datepicker-dates`}>
                {getWeekdayNames(localeCode).map((day, index) => (
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    className={`${cssPrefix}-day-heading`}
                    key={`${day}${index}`}
                  >
                    {day}
                  </Typography>
                ))}
                {daysInMonth.map((date) => {
                  const isDisabledDate = isDateDisabled(date);
                  const startDisabledStyle =
                    isDisabledDate &&
                    !isDateDisabled(subtractTime(date, 'days', 1));
                  const endDisabledStyle =
                    isDisabledDate && !isDateDisabled(addTime(date, 'days', 1));
                  return (
                    <Typography
                      key={date.toISOString()}
                      variant="body2"
                      className={clsx([
                        `${cssPrefix}-day`,
                        `${cssPrefix}-day--${date.getDate()}`,
                        isToday(date) && 'today',
                        !isSame(navigatedDate, date, 'month') &&
                          'outside-month',
                        isSame(value, date, 'day') && 'selected',
                        isDisabledDate && 'disabled-date',
                        endDisabledStyle && 'disabled-date-end',
                        startDisabledStyle && 'disabled-date-start',
                        startDisabledStyle &&
                          endDisabledStyle &&
                          'disabled-date-single',
                      ])}
                      as="div"
                      tabIndex={
                        isSame(navigatedDate, date, 'day') &&
                        !isDateDisabled(date)
                          ? 0
                          : -1
                      }
                      ref={
                        isSame(navigatedDate, date, 'day')
                          ? navigatedDateRef
                          : null
                      }
                      onClick={
                        !disabledDates || !disabledDates(date)
                          ? (e) => handleDateClicked(e, date)
                          : undefined
                      }
                      onKeyDown={handleKeyDown}
                      aria-selected={isSame(value, date, 'day')}
                      role="option"
                      aria-disabled={isDateDisabled(date)}
                    >
                      {/* gets the day of the date */}
                      {getUnit(date, 'day')}
                    </Typography>
                  );
                })}
              </div>
            </div>
          </TabLoop>
        </BasePopper>
      </div>
    );
  }
);
